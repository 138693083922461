import React from 'react';
import { components } from 'react-select';
import coat from '../../images/sk-coat-of-arms.svg';

export const slovakiaOption = {
  value: 'Slovensko',
  label: 'Slovensko',
};

export const CustomSelectOption = (props) =>
  props.value === slovakiaOption.value ? (
    <components.Option {...props}>
      <img src={coat} alt="" width="15" style={{ float: 'left', marginRight: '0.5em' }} /> {props.children}
    </components.Option>
  ) : (
    <components.Option {...props} />
  );
